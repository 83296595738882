
.splash {
  box-sizing: border-box;
  padding-top: 48px;
  height: 100vh;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.splashInstructions {
  font-size: 80%;
  padding: 2em 0;
  width: 100%;
  background-color: rgba(0,0,0,0.9);
}

.splashLogo {
  max-width: 100%;
}

.splashScoreTitle {
  font-size: 1.4rem;
  font-weight: 600;
  text-shadow: 1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black;
}

.splashScoreValue {
  font-size: 2rem;
  text-shadow: 1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black;
}

.splashScoreBallTime {
  text-shadow: 1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black;
}

.mode {
  margin-bottom: 18px;
}
