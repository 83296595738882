.app {
  text-align: center;
  background-color: #111111;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  max-width: 520px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media(min-width: 520px) {
  .app {
    background-color: black;
    border: solid 1px #666;
    border-width: 0 1px;
    margin: 0 auto;
  }
}

.appBrand {
  width: 100px;
  opacity: 0.5;
}

.appTitle {
  flex-grow: 1;
  font-size: calc(10px + 2vmin);
  color: white;
}

.appNav {
  width: 100px;
  opacity: 0.5;
}

.appHeader {
  background-color: #282c34;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  height: 54px;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: calc(100vh - 54px);
  z-index: 100;
  border-top: solid 1px transparent;
  box-sizing: border-box;
}

.appScrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111111;
  z-index: 10;
  padding-top: 24px;
  padding-bottom: 64px;
  border-top: solid 1px transparent;
}

.appContent {
  width: 480px;
  max-width: 90vw;
}

.loadingApp {
  background-color: #111111;
}

.emptyApp {
  background-color: #009988;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  color: #70e2d5;
}

.emptyCopy {
  padding-bottom: 40px;
}

.mode {
  margin: 28px 0;
}
