.modeCategory {
  font-family: "me123";
  font-size: 0.9em;
  padding: 28px 0 0 28px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.modeContainer {
  border-style: solid;
  border-color: #000000;
  border-image-slice: 28 fill;
  border-image-width: 28px;
  border-image-repeat: repeat;
}

.modeImageContainer {
  background-size: 100%;
  background-repeat: no-repeat;
}

.modeImage {
  width: 100%;
  z-index: 1;
}

.modeContent {
    text-align: justify;
    padding: 0 6.25% 8px 6.25%;
}

.MuiAccordion-root {
    margin: 13px 0 -13px 0;
    background-color: transparent;
    position: relative;
    box-shadow: none;
    padding-bottom: 26px;
}

.modeDescriptionText {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.modeDescriptionTitle {
    font-family: "me123";
    padding-bottom: 4px;
}

.modeDescriptionToggle {
    text-align: center;
    flex-grow: 1;
    padding: 0;
    margin: 20px auto 0 auto !important;
    height: 64px;
    font-size: 1rem;
}

.modeDescriptionIcon {
  color: inherit;
}

.modeStatus {
    text-align: left;
    font-family: "me123";
    margin: 13px 0 8px 0;
}

.modeSummary {
    font-size: 0.8em;
    color: #BBBBBB;
    font-style: italic;
    text-align: justify;
}

.modeTitle {
    font-weight: 400;
    font-size: 1.2em;
    font-family: "me123";
    padding: 13px 28px;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
}
